<template>
  <div>

    <div style="border: 1px solid #ccc; margin-top: 10px;margin-bottom:20px">
      <!-- 工具栏 -->
      <Toolbar
        style="border-bottom: 1px solid #ccc"
        :editor="editor"
        :defaultConfig="toolbarConfig"
      />
      <!-- 编辑器 -->
      <Editor
        style="height: 400px; overflow-y: hidden"
        :defaultConfig="editorConfig"
        v-model="content"
        @onChange="onChange"
        @onCreated="onCreated"
      />
    </div>
   
  </div>
</template>

<script>
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";

export default {
  name: "MyEditor",
  components: { Editor, Toolbar },
  props: {
    /*编辑器的内容*/
    value: {
      type: String
    },
    action: {
      type: String
    },
    /*图片大小*/
    maxSize: {
      type: Number,
      default: 4000 //kb
    }
  },
 
  data() {
    var baseUrl = this.$base.url;
    return {
      content: this.value,
      editor: null,
      toolbarConfig: {
        // toolbarKeys: [ /* 显示哪些菜单，如何排序、分组 */ ],
        // excludeKeys: [ /* 隐藏哪些菜单 */ ],
      },
      editorConfig: {
        placeholder: "请输入内容...",
        // autoFocus: false,

        // 所有的菜单配置，都要在 MENU_CONF 属性下
        MENU_CONF: {
          uploadImage: {
            server:  `${this.$base.url}file/upload`, 
            fieldName: 'file',

              // 将 meta 拼接到 url 参数中，默认 false
              metaWithUrl: false,

              // 自定义增加 http  header
              headers: {
                'Token': this.$storage.get("Token")
              },

              // 跨域是否传递 cookie ，默认为 false
              withCredentials: true,
               // 单个文件上传成功之后
              onSuccess(file, res) {  // TS 语法
              // onSuccess(file, res) {          // JS 语法
                  console.log(`${file.name} 上传成功`, res)
              },
              customInsert(res, insertFn) {                  // JS 语法
                // res 即服务端的返回结果
                var that = this;

                // 从 res 中找到 url alt href ，然后插入图片
                // var url =  baseUrl+res.file
                var url =  res.data.url

                insertFn(url, "", "")
              },
          },
        },
      },
    };
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor); // 【注意】一定要用 Object.seal() 否则会报错
    },
    onChange(editor) {
      // console.log("onChange", editor.getHtml()); // onChange 时获取编辑器最新内容
        //内容改变事件
        this.$emit("input", editor.getHtml());
    },
    getEditorText() {
      const editor = this.editor;
      if (editor == null) return;

      console.log(editor.getText()); // 执行 editor API
    },
    printEditorHtml() {
      const editor = this.editor;
      if (editor == null) return;

      console.log(editor.getHtml()); // 执行 editor API
    },
  },
  mounted() {
console.log("this.value",this.value)
var that = this;
   setTimeout(() => {
    that.content =  that.value; 
   },1000)

    // this.editorConfig.MENU_CONF['uploadImage'] = {
          
    //           server:  `${this.$base.url}file/upload`, 
    //           // 将 meta 拼接到 url 参数中，默认 false
    //           metaWithUrl: false,

    //           // 自定义增加 http  header
    //           headers: {
    //             'Token': this.$storage.get("Token")
    //           },

    //           // 跨域是否传递 cookie ，默认为 false
    //           withCredentials: true,
    //            // 单个文件上传成功之后
    //           onSuccess(file, res) {  // TS 语法
    //           // onSuccess(file, res) {          // JS 语法
    //               console.log(`${file.name} 上传成功`, res)
    //           },

    //       };
    
 
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁 editor ，重要！！！
  },
};
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>
